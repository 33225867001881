import React from "react";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useScript } from "../../contexts/scriptContext";
import { useQuest } from "../../contexts/questContext";
//import { useItem } from "../../contexts/itemContext";
import { copyText } from "../../utils/textUtils";

const ScriptPreview = () => {
  const { viewScript } = useScript();
  const { setQuestKeyword, getQuestInfoById } = useQuest();
  //const { setKeyword } = useItem();
  const history = useHistory();

  let npcs = [];
  let items = [];
  let variables = [];
  let inputStrs = [];
  let quests = [];

  if (viewScript) {
    npcs = viewScript.fileInfo.npcs;
    items = viewScript.fileInfo.items;
    variables = viewScript.fileInfo.variables;
    inputStrs = viewScript.fileInfo.inputStrs;
    quests = viewScript.fileInfo.quests;
  }

  // function searchItem(id) {
  //   //history.push("/items");
  // }

  function searchQuest(id) {
    setQuestKeyword(id);
    history.push("/quests");
  }

  function getQuestName(id) {
    const questName = getQuestInfoById(id, "name");
    if (questName) return " - " + questName;
    return "";
  }

  return viewScript ? (
    <>
      <Row className="mb-3">
        <Col>
          <div>
            <code>{viewScript.relativePath}</code>
          </div>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h4>Item ({items.length})</h4>
          <ul className="row script-list-items list-unstyled">
            {items.map((item, index) => {
              return (
                <li className="col-3" key={index}>
                  <Button
                    variant="outline-secondary"
                    onClick={() => copyText(item)}
                  >
                    {item}
                  </Button>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>

      <hr />

      <Row className="mb-3">
        <Col>
          <h4>Quest ({quests.length})</h4>
          <ul className="row script-list-items list-unstyled">
            {quests.map((quest, index) => {
              return (
                <li className="col-3" key={index}>
                  <Button
                    variant="outline-secondary"
                    onClick={() => searchQuest(quest)}
                  >
                    {`${quest} ${getQuestName(quest)}`}
                  </Button>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h4>InputStr ({inputStrs.length})</h4>
          <ul className="row list-unstyled">
            {inputStrs.map((inputStr, index) => {
              return <li className="col-3" key={index}>{`${inputStr}`}</li>;
            })}
          </ul>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h4>Variables ({variables.length})</h4>
          <ul className="row list-unstyled">
            {variables.map((variable, index) => {
              return <li className="col-3" key={index}>{`${variable}`}</li>;
            })}
          </ul>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <h4>NPC ({npcs.length})</h4>
          <ul className="row list-unstyled">
            {npcs.map((npc, index) => {
              return (
                <Col as="li" md={3} key={index}>
                  <div>
                    <img
                      height={120}
                      className="mr-3"
                      src={`https://whisper.hahiu.com/ro/npc/${
                        npc.sprite ? npc.sprite : "CLEAR_NPC"
                      }.GIF`}
                      alt={npc.sprite}
                    />
                    <div>
                      <h6 className="mb-1">
                        {npc.npcName}
                        {npc.npcNameHide ? `#${npc.npcNameHide}` : ""}
                      </h6>
                      <p>
                        <code
                          className="d-block c-pointer"
                          onClick={() =>
                            copyText(`/mm ${npc.map}.gat ${npc.x} ${npc.y}`)
                          }
                        >{`/mm ${npc.map}.gat ${npc.x} ${npc.y}`}</code>
                        <code
                          className="d-block c-pointer"
                          onClick={() =>
                            copyText(`/navi ${npc.map} ${npc.x}/${npc.y}`)
                          }
                        >{`/navi ${npc.map} ${npc.x}/${npc.y}`}</code>
                      </p>
                      <p></p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </ul>
        </Col>
      </Row>
    </>
  ) : null;
};

export default ScriptPreview;
